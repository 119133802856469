import {
  Button,
  Paper,
  Typography,
  Box,
  TextField,
} from '@mui/material';

import Page from '../components/Page';
import SingleDataCard from '../components/SingleDataCard';
import CustomTable from '../components/CustomTable';
import ProvinceService from '../services/Province';
import WeatherConditionService from '../services/WeatherCondition';
import UtilsService from '../services/Utils';
import WeatherConditionModel from '../models/WeatherCondition';
import WeatherChart from '../components/WeatherChart';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useUser from '../hooks/user';
import { Role } from '../models/User';
import ProvinceModel from '../models/Province';
import TriggerDot from '../components/TriggerDot';
import moment from 'moment';
import Cover from '../models/Cover';
import { DatePicker } from '@mui/x-date-pickers';
import { ConditionType } from '../models/WeatherCondition';
import { NavLink as RouterLink } from 'react-router-dom';

const headers = [
  { index: 'date', title: 'Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'type', title: 'Peril' },
  { index: 'event', title: 'Event' },
  { index: 'weatherCondition', title: 'Weather condition', transformer: (amount: number, type: ConditionType) => UtilsService.formatWeatherCondition(type, amount) },
  { index: 'trigger', title: 'Trigger' },
  { index: 'benefit', title: 'Payout', transformer: (v: number) => UtilsService.formatCurrency(v) },
]

interface Content {
  id: number,
  date: string,
  weatherCondition: number,
  type: string,
  event: string,
  trigger: string,
  benefit: number,
}

function DashboardProvince() {
  const { user } = useUser();
  const { provinceId, insureeId } = useParams();
  const [province, setProvince] = useState<ProvinceModel>();
  const [benefits, setBenefits] = useState<Content[]>([]);
  const [coverage, setCoverage] = useState<number>(0);
  const [covers, setCovers] = useState<Cover[]>([]);
  const [totalBenefits, setTotalBenefits] = useState<number>(0);
  const [rainfall, setrainfall] = useState<WeatherConditionModel[]>([]);
  const [windSpeed, setwindSpeed] = useState<WeatherConditionModel[]>([]);

  const [coverageDate, setCoverageDate] = useState<moment.Moment | null>(moment());

  const initInsureeProvinceView = useCallback(() => {
    ProvinceService.getMyProvince(provinceId ?? '0').then((res) => {
      setProvince(res);
      setCovers(res.covers);
      setCoverage(res.covers.filter(c => moment(c.policy.start) <= (coverageDate || moment()) && (coverageDate || moment()) <= moment(c.policy.end)).reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
      const benefits = res.covers.map(c => c.benefits).flat();
      setTotalBenefits(benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
      const contents = benefits.map(b => {
        const content: Content = {
          id: b.id,
          date: b.weatherCondition.date,
          type: b.weatherCondition.index.name,
          event: b.weatherCondition.event,
          weatherCondition: parseFloat(b.weatherCondition.value),
          trigger: b.trigger,
          benefit: parseFloat(b.amount)
        };
        return content;
      });
      setBenefits(contents);
    },
      err => { console.log(err) }
    );
  }, [provinceId, coverageDate]);

  const initNoneInsureeProvinceView = useCallback(() => {
    ProvinceService.getProvinceOfInsuree(provinceId ?? '0', insureeId ?? '0').then((res) => {
      setProvince(res);
      setCovers(res.covers);
      setCoverage(res.covers.filter(c => moment(c.policy.start) <= (coverageDate || moment()) && (coverageDate || moment()) <= moment(c.policy.end)).reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
      const benefits = res.covers.map(c => c.benefits).flat();
      setTotalBenefits(benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
      const contents = benefits.map(b => {
        const content: Content = {
          id: b.id,
          date: b.weatherCondition.date,
          type: b.weatherCondition.index.name,
          event: b.weatherCondition.event,
          weatherCondition: parseFloat(b.weatherCondition.value),
          trigger: b.trigger,
          benefit: parseFloat(b.amount)
        };
        return content;
      });
      setBenefits(contents);
    },
      err => { console.log(err) }
    );
  }, [provinceId, insureeId, coverageDate]);

  const updateTable = useCallback(() => Promise.resolve({ total: 0, rows: benefits }), [benefits]);

  useEffect(() => {
    if (user.role === Role.Insuree) {
      initInsureeProvinceView();
    } else {
      initNoneInsureeProvinceView();
    }
  }, [initInsureeProvinceView, initNoneInsureeProvinceView, user.role]);

  useEffect(() => {
    setCoverage(covers.filter(c => moment(c.policy.start) <= (coverageDate || moment()) && (coverageDate || moment()) <= moment(c.policy.end)).reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
    const benefits = covers/* .filter(c => moment(c.from) <= moment() && moment() <= moment(c.to) ) */.map(c => c.benefits).flat();
    setTotalBenefits(benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
  }, [covers, provinceId, insureeId, coverageDate]);

  useEffect(() => {
    WeatherConditionService.getByProvince(provinceId ?? '0').then((weatherConditions) => {
      const filteredProvinces = province ? province.covers.filter(c => moment(c.policy.start) <= moment() && moment() <= moment(c.policy.end)) : null;
      const startOfCoverage = filteredProvinces && filteredProvinces.length > 0 ? moment(filteredProvinces.reduce((pre, cur) => moment(pre.policy.start).isSameOrBefore(cur.policy.start) ? pre : cur).policy.start) : null;
      const endOfCoverage = filteredProvinces && filteredProvinces.length > 0 ? moment(filteredProvinces.reduce((pre, cur) => moment(pre.policy.end).isSameOrAfter(cur.policy.end) ? pre : cur).policy.end) : null;
      const filteredConditions = weatherConditions
        .filter(condition => startOfCoverage ? moment(condition.date).isSameOrAfter(startOfCoverage) : true) // must be after coverage start
        .filter(condition => endOfCoverage ? moment(condition.date).isSameOrBefore(endOfCoverage) : true) // must be before coverage end
        .filter(condition => moment(condition.date).isSameOrBefore(moment())) // must be before current date
        .filter((condition, ind, arr) => moment(condition.date).isSameOrAfter(moment(arr[arr.length - 1].date).startOf('day').add(-3, 'months'))) // must be after last date - 3 months
        .sort((a, b) => moment(a.date) > moment(b.date) ? 1 : -1)
      setrainfall(filteredConditions.filter((condition) => condition.index.name === ConditionType.rain));
      setwindSpeed(filteredConditions.filter((condition) => condition.index.name === ConditionType.wind));
    },
      err => { console.log(err) }
    );
  }, [province, provinceId])

  return (
    <Page title={`Dashboard for ${province?.name}`}>
      <>
        {user.role !== Role.Insuree &&
          <Box mb={3} width={300}>
            <DatePicker
              label='Coverage Date'
              value={coverageDate}
              onChange={(v) => setCoverageDate(v)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Box>
        }
      </>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex' }}>
            <SingleDataCard title={'Coverage'} content={`${UtilsService.formatCurrency(coverage)}`} />
            <SingleDataCard title={'Total Payouts'} content={`${UtilsService.formatCurrency(totalBenefits)}`} />
          </Box>
          <>
            {province && (
              <Box style={{ display: 'flex', width: '100%' }}>
                <Paper sx={{
                  p: 2,
                  m: 2,
                  width: '100%'
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    height: '16rem',
                  }}>
                    <Box sx={{ width: '8rem' }}>
                      <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                        Rainfall
                      </Typography>
                      <Box><TriggerDot level={0} /> {UtilsService.formatPrecipitation(province.triggers.find((t) => t.index.name === ConditionType.rain)?.yellow ?? 0)}</Box>
                      <Box><TriggerDot level={1} /> {UtilsService.formatPrecipitation(province.triggers.find((t) => t.index.name === ConditionType.rain)?.orange ?? 0)}</Box>
                      <Box><TriggerDot level={2} /> {UtilsService.formatPrecipitation(province.triggers.find((t) => t.index.name === ConditionType.rain)?.red ?? 0)}</Box>
                    </Box>
                    <Box sx={{ width: 'calc(100% - 8rem)' }}>
                      <WeatherChart
                        values={rainfall}
                        red_trigger={province.triggers.find((t) => t.index.name === ConditionType.rain)?.red ?? 0}
                        orange_trigger={province.triggers.find((t) => t.index.name === ConditionType.rain)?.orange ?? 0}
                        yellow_trigger={province.triggers.find((t) => t.index.name === ConditionType.rain)?.yellow ?? 0}
                        type={ConditionType.rain}
                        min_date={(rainfall.length > 0 ? moment(rainfall[0].date) : moment()).startOf('day').valueOf()}
                        max_date={(rainfall.length > 0 ? moment(rainfall[rainfall.length - 1].date) : moment()).startOf('day').valueOf()}
                        hide_legend
                      />
                    </Box>
                  </Box>
                </Paper>
                <Paper sx={{
                  p: 2,
                  m: 2,
                  width: '100%'
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    height: '16rem',
                  }}>
                    <Box sx={{ width: '8rem' }}>
                      <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                        Wind speed
                      </Typography>
                      <Box><TriggerDot level={0} /> {UtilsService.formatWindSpeed(province.triggers.find((t) => t.index.name === ConditionType.wind)?.yellow ?? 0)}</Box>
                      <Box><TriggerDot level={1} /> {UtilsService.formatWindSpeed(province.triggers.find((t) => t.index.name === ConditionType.wind)?.orange ?? 0)}</Box>
                      <Box><TriggerDot level={2} /> {UtilsService.formatWindSpeed(province.triggers.find((t) => t.index.name === ConditionType.wind)?.red ?? 0)}</Box>
                    </Box>
                    <Box sx={{ width: 'calc(100% - 8rem)' }}>
                      <WeatherChart
                        values={windSpeed}
                        red_trigger={province.triggers.find((t) => t.index.name === ConditionType.wind)?.red ?? 0}
                        orange_trigger={province.triggers.find((t) => t.index.name === ConditionType.wind)?.orange ?? 0}
                        yellow_trigger={province.triggers.find((t) => t.index.name === ConditionType.wind)?.yellow ?? 0}
                        type={ConditionType.wind}
                        min_date={(windSpeed.length > 0 ? moment(windSpeed[0].date) : moment()).startOf('day').valueOf()}
                        max_date={(windSpeed.length > 0 ? moment(windSpeed[windSpeed.length - 1].date) : moment()).startOf('day').valueOf()}
                        hide_legend
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>
            )}
          </>
          <CustomTable
            headers={headers}
            updateFunction={updateTable}
            disableSearch
            disablePagination
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Button
          variant="contained"
          color="secondary"
          component={RouterLink}
          to="/dashboard"
          sx={{ m: 2 }}
        >
          Return
        </Button>
      </Box>
    </Page>
  );
}

export default DashboardProvince;
