import { Box, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import PendingEventsTable from '../components/admin/PendingEventsTable';
import Page from '../components/Page';
import WeatherChart from '../components/WeatherChart';
import TriggerDot from '../components/TriggerDot';
import EventModel from '../models/EventsView';
import WeatherConditionModel, { ConditionType } from '../models/WeatherCondition';
import EventService from '../services/EventsView';
import weatherConditionService from '../services/WeatherCondition';
import UtilsService from '../services/Utils';

const headers = [
  { index: 'province', title: 'Province' },
  { index: 'date', title: 'Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'type', title: 'Type' },
  { index: 'weatherEvent', title: 'Event' },
  { index: 'value', title: 'Value', transformer: (amount: number, type: ConditionType) => UtilsService.formatWeatherCondition(type, amount) },
  { index: null, title: 'Trigger', special: 'triggerSelection' },
  { index: null, title: 'Diff', special: 'diff', transformer: (e: EventModel) => UtilsService.computePrecipitationDiff(e.value, e.yellow_trigger) },
  { index: null, title: 'Accepted', special: 'accepted' },
  { index: 'acceptedAt', title: 'Accepted date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'acceptedBy', title: 'Accepted by', transformer: (u: string) => u ? u : '-' },
  { index: null, title: 'Action', special: 'save' },
]

function PendingEvents() {
  const [events, setEvents] = useState<EventModel[]>([]);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageNb, setPageNb] = useState<number>(0);
  const [provinceFilter, setProvinceFilter] = useState<string>('');
  const [totalEvents, setTotalEvents] = useState<number>(0);
  const [selectedEvent, setSelectedEvent] = useState<EventModel>();
  const [weatherConditions, setWeatherConditions] = useState<WeatherConditionModel[]>([]);
  const [refresh, setRefresh] = useState<boolean>(true);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    EventService.getAllEvents(pageSize, pageNb, provinceFilter).then(res => {
      setEvents(res[0]);
      setTotalEvents(res[1]);
    }).catch(console.error).finally(() => setLoading(false));
  }, [pageSize, pageNb, provinceFilter, refresh]);

  useEffect(() => {
    if (selectedEvent) {
      weatherConditionService.getByProvinceBetween(
        selectedEvent.provinceId.toString(),
        UtilsService.getXdaysBefore(7, selectedEvent.date),
        UtilsService.getXdaysAfter(3, selectedEvent.date),
        selectedEvent.type
      ).then(setWeatherConditions).catch(console.error);
    }
  }, [selectedEvent]);

  const handleChangePage = (pageSize: number, pageNb: number) => {
    setPageNb(pageNb);
    setPageSize(pageSize);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    setPageNb(0);
    setPageSize(pageSize);
  };

  const handleChangeSelected = (rowNb: number) => {
    setSelectedEvent(events[rowNb]);
  };

  const handleChangeSearch = (filter: string) => {
    setPageNb(0);
    setProvinceFilter(filter);
  };

  const handleSaveEvent = () => {
    setRefresh(ref => !ref);
  };

  const handleDeleteEvent = () => {
    setRefresh(ref => !ref);
  };

  return (
    <>
      <Page title={`Pending Events`}>
        <PendingEventsTable
          headers={headers}
          content={events}
          total={totalEvents}
          pageNb={pageNb}
          pageSize={pageSize}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangeSearch={handleChangeSearch}
          onChangeSelected={handleChangeSelected}
          onSave={handleSaveEvent}
          onDelete={handleDeleteEvent}
          isLoading={isLoading}
        />
        {selectedEvent ? (
          <Paper sx={{ m: 2, p: 2 }}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              Province triggers for {selectedEvent.type} - {selectedEvent.province}
            </Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                justifyContent: 'space-evenly'
              }}>
                <Box><b>Date :</b> {UtilsService.formatDate(selectedEvent.date)}</Box>
                <Box><b>Value :</b> {UtilsService.formatWeatherCondition(selectedEvent.type, selectedEvent.value)}</Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%'
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                    justifyContent: 'space-evenly'
                  }}>
                    <Box><b>Triggers</b></Box>
                    <Box><TriggerDot level={0} /> {UtilsService.formatWeatherCondition(selectedEvent.type, selectedEvent.yellow_trigger)}</Box>
                    <Box><TriggerDot level={1} /> {UtilsService.formatWeatherCondition(selectedEvent.type, selectedEvent.orange_trigger)}</Box>
                    <Box><TriggerDot level={2} /> {UtilsService.formatWeatherCondition(selectedEvent.type, selectedEvent.red_trigger)}</Box>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                    justifyContent: 'space-evenly'
                  }}>
                    <Box><b>Diff</b></Box>
                    <Box>{UtilsService.computePrecipitationDiff(selectedEvent.value, selectedEvent.yellow_trigger)}</Box>
                    <Box>{UtilsService.computePrecipitationDiff(selectedEvent.value, selectedEvent.orange_trigger)}</Box>
                    <Box>{UtilsService.computePrecipitationDiff(selectedEvent.value, selectedEvent.red_trigger)}</Box>
                  </Box>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  width: '200px'
                }}>
                  <Box><b>Diff</b> = </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    '& > *': {
                      display: 'flex',
                      justifyContent: 'center',
                    },
                    '& *:first-of-type': {
                      borderBottom: '1px solid'
                    },
                  }}>
                    <Box>value - trigger</Box>
                    <Box>value</Box>
                  </Box>
                  <Box> x 100%</Box>
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                justifyContent: 'space-evenly'
              }}>
                <WeatherChart
                  yellow_trigger={selectedEvent.yellow_trigger}
                  orange_trigger={selectedEvent.orange_trigger}
                  red_trigger={selectedEvent.red_trigger}
                  values={weatherConditions}
                  type={selectedEvent.type}
                />
              </Box>
            </Box>
          </Paper>
        ) : <></>}
      </Page>
    </>
  );
}

export default PendingEvents;