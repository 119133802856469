import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import { FormData } from "../PolicyRegistration";
import UtilsService from "../../../services/Utils";

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  selectedProvinces: FormData | null;
};

function PolicyRegistrationSummary({
  onCancel,
  onConfirm,
  selectedProvinces,
}: Props) {
  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography>Please find below the summary of your coverage for 2023-2024.</Typography>
          <Divider sx={{ my: 2 }}><Chip label="Coverages" /></Divider>
          {selectedProvinces && (
            <>
              {selectedProvinces.provinces.map((province, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    overflow: "hidden",
                    "::before": {
                      float: "left",
                      width: 0,
                      whiteSpace: "nowrap",
                      content:
                        "'...........................................................................................................................................................................................................................'",
                      color: "#ccc"
                    },
                  }}
                >
                  <Typography display="inline" sx={{ backgroundColor: theme => theme.palette.background.paper, pr: 1 }}>{province.name}</Typography>
                  <Typography display="inline" sx={{ backgroundColor: theme => theme.palette.background.paper, pl: 1, ml: "auto" }}>
                    {UtilsService.formatCurrency(province.amount)}
                  </Typography>
                </Box>
              ))}
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  overflow: "hidden",
                  "::before": {
                    float: "left",
                    width: 0,
                    whiteSpace: "nowrap",
                    content:
                      "'...........................................................................................................................................................................................................................'",
                    color: "#ccc"
                  },
                }}
              >
                <Typography display="inline" sx={{ backgroundColor: theme => theme.palette.background.paper, pr: 1 }}>TOTAL</Typography>
                <Typography display="inline" sx={{ backgroundColor: theme => theme.palette.background.paper, pl: 1, ml: "auto" }}>
                  {UtilsService.formatCurrency(selectedProvinces.provinces.reduce((prev, curr) => prev + Number(curr.amount), 0))}
                </Typography>
              </Box>
            </>
          )}
            <Divider sx={{ my: 2 }}><Chip label="Note" /></Divider>
            <Typography>Please check that all details are correct and click 'confirm'.</Typography>
        </CardContent>
      </Card>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="error"
          sx={{ mr: 1 }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" sx={{ ml: 1 }} onClick={onConfirm}>
          Confirm
        </Button>
      </Box>
    </>
  );
}

export default PolicyRegistrationSummary;
