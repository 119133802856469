import { Box } from '@mui/material';
import Page from '../components/Page';
import SingleDataCard from '../components/SingleDataCard';
import CustomTable from '../components/CustomTable';
import CoverService from '../services/Cover';
import BenefitService from '../services/Benefit';
import UtilsService from '../services/Utils';
import { useCallback, useEffect, useState } from 'react';
import ProvinceSelector from '../components/ProvinceSelector';
import useUser from '../hooks/user';
import TemporalityToggleButtons, { TemporalityEnum } from '../components/TemporalityToggleButtons';
import PolicyStatusToggleButtons from '../components/PolicyStatusToggleButtons';
import { PolicyStatus } from '../models/Policy';

const headers = [
  { index: 'coopName', title: 'Cooperative Name' },
  { index: 'coverage', title: 'Coverage', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'benefits', title: 'Payout', transformer: (v: number) => UtilsService.formatCurrency(v) }
];

interface Content {
  id: number,
  coopName: string,
  coverage: number,
  benefits: number,
};

function OverviewCoop() {
  const { selectedProvinceId } = useUser();
  const [totalCoverage, setTotalCoverage] = useState<number>(0);
  const [totalBenefits, setTotalBenefits] = useState<number>(0);

  const [tempo, setTempo] = useState<TemporalityEnum>(TemporalityEnum.current);
  const [policyStatus, setPolicyStatus] = useState<PolicyStatus>(PolicyStatus.inForce);

  useEffect(() => {
    CoverService.getCoversByProvince(selectedProvinceId ? `${selectedProvinceId}` : '0', policyStatus, tempo).then(res => {
      setTotalCoverage(res.values.reduce<number>((prev, curr) => prev + parseFloat(curr.amount), 0));
    });
    BenefitService.getTotalBenefitsValueByProvince(selectedProvinceId ? `${selectedProvinceId}` : '0', policyStatus, tempo).then(res => setTotalBenefits(res.sum));
  }, [selectedProvinceId, policyStatus, tempo]);

  const updateCoops = useCallback((pageNb: number, pageSize: number, filter: string) => {
    return CoverService.getCoversByProvince(selectedProvinceId ? `${selectedProvinceId}` : '0', policyStatus, tempo, pageSize, pageNb, filter).then(res => {
      const contents: Content[] = res.values.map(c => {
        const content = {
          id: c.id,
          coopName: c.policy.insuree.name,
          coverage: parseFloat(c.amount),
          benefits: c.benefits.length > 0 ? c.benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0) : 0
        };
        return content;
      });
      return { total: res.count, rows: contents };
    });
  }, [selectedProvinceId, policyStatus, tempo]);

  return (
    <Page title={"Province Overview"}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2, ml: 2 }}>
        <ProvinceSelector />
      </Box>
      <Box sx={{
        display: 'flex',
        width: '100%',
      }}>
        <TemporalityToggleButtons
          color="primary"
          onChange={(value) => setTempo(value)}
        />
        <PolicyStatusToggleButtons
          color="primary"
          onChange={(value) => setPolicyStatus(value)}
        />
        <SingleDataCard title={'Total Coverage'} content={`${UtilsService.formatCurrency(totalCoverage)}`} />
        <SingleDataCard title={'Total Payouts'} content={`${UtilsService.formatCurrency(totalBenefits)}`} />
      </Box>
      <CustomTable
        headers={headers}
        updateFunction={updateCoops}
        pageSizeOptions={[10, 15, 20]}
      />
    </Page>
  );
}

export default OverviewCoop;