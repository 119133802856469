import {
  Drawer as MuiDrawer,
  List,
  Button,
  Chip,
  Typography,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import OverviewIcon from '@mui/icons-material/HomeOutlined';
import CoopManagerIcon from '@mui/icons-material/BackupTable';
import ProvincesViewerIcon from '@mui/icons-material/ListOutlined';
import ProductIcon from '@mui/icons-material/ShoppingCartOutlined';
import DashboardIcon from '@mui/icons-material/Public';
import RegistrationIcon from '@mui/icons-material/AppRegistration';
import TriggeredEventsIcon from '@mui/icons-material/DateRangeOutlined';
import ContactIcon from '@mui/icons-material/PersonOutlineOutlined';
import AdminIcon from '@mui/icons-material/SettingsOutlined';
import LogsIcon from '@mui/icons-material/LogoDev';
import ActivityIcon from '@mui/icons-material/MonitorHeartOutlined';
import UserProfileIcon from '@mui/icons-material/Tune';
import EcoIcon from '@mui/icons-material/SpaOutlined';
import EventIcon from '@mui/icons-material/Event';
import CloudIcon from '@mui/icons-material/WbCloudyOutlined';
import HelpIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/ArrowBackIosOutlined';
import OpenIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Role } from '../models/User';
import useUser from '../hooks/user';
import AuthService from '../services/Authenticate';
import PolicyService from '../services/Policy';
import _envConfig from '../configurations/env.json';
import { Env } from '../configurations/env';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { useCallback, useEffect, useState } from 'react';
import useDrawer from '../hooks/drawer';
import DrawerListItem, { MenuItemProps } from './DrawerListItem';

const menu: MenuItemProps[] = [
  {
    name: 'Overview',
    id: 'linkToOverview',
    icon: OverviewIcon,
    link: '/overview',
    roles: [Role.Admin, Role.SuperUser],
  },
  {
    name: 'Cooperatives',
    id: 'linkToCoopManager',
    icon: CoopManagerIcon,
    link: '/coopManager',
    roles: [Role.Admin, Role.SuperUser],
  },
  {
    name: 'Provinces',
    id: 'linkToProvinceViewer',
    icon: ProvincesViewerIcon,
    link: '/provinceViewer',
    roles: [Role.Admin, Role.SuperUser],
  },
  {
    name: 'Dashboard',
    id: 'linkToDashboard',
    icon: DashboardIcon,
    link: '/dashboard',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Policy Registration',
    id: 'linkToPolicyRegistration',
    icon: RegistrationIcon,
    link: '/policy-registration',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Product',
    id: 'linkToProduct',
    icon: ProductIcon,
    link: '/product',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Historical Weather',
    id: 'linkToHistorical',
    icon: CloudIcon,
    link: '/historical',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Triggered Events',
    id: 'linkToTriggered',
    icon: TriggeredEventsIcon,
    link: '/triggered-events',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Recommendations',
    id: 'linkToRecommendations',
    icon: EcoIcon,
    link: '/recommendations',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Contact',
    id: 'linkToContact',
    icon: ContactIcon,
    link: '/contact',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Admin',
    id: 'AdminSubMenu',
    icon: AdminIcon,
    link: '/admin',
    roles: [Role.Admin],
    subMenu: [
      {
        name: 'Mangment',
        id: 'linkToLogs',
        icon: LogsIcon,
        link: '/admin/managment',
      },
      {
        name: 'Logs',
        id: 'linkToLogs',
        icon: LogsIcon,
        link: '/admin/logs',
      },
      {
        name: 'Activity',
        id: 'linkToActivity',
        icon: ActivityIcon,
        link: '/admin/activity',
      },
    ]
  },
  {
    name: 'Pending Events',
    id: 'linkToPending',
    icon: EventIcon,
    link: '/pendingEvents',
    roles: [Role.Admin],
  },
  {
    name: 'My Profile',
    id: 'linkToProfile',
    icon: UserProfileIcon,
    link: '/me',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
];

const drawerWidth = 240;

const filterMenuWithUserRole = (role: Role) =>
  menu.filter(({ roles }) => roles.indexOf(role) >= 0);

function Drawer() {
  const { user } = useUser();
  const env: Env = (process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'dev') as Env;
  const envConfig = _envConfig.themes.filter(t => t.envs.includes(env))[0];

  const [hasPreviousPolicy, setHasPreviousPolicy] = useState(false);

  const navigate = useNavigate();
  const { drawerOpened, setDrawerOpened } = useDrawer();

  const [run, setRun] = useState(window.localStorage.getItem('joyrideFinished') !== 'true');
  const [stepIndex, setStepIndex] = useState(0);
  const [steps] = useState<Step[]>([
    {
      target: 'body',
      placement: 'center',
      content: 'Great to see you here! Let me show you the most important things on this platform. Let\'s get started!',
      showSkipButton: false,
      hideCloseButton: true,
      event: 'hover'
    },
    {
      target: '#helpIcon',
      spotlightPadding: 10,
      content: 'You can skip this tour anytime and come back to it using this button.',
      hideCloseButton: true,
      event: 'hover',
      offset: 0
    },
    {
      target: '#linkToProduct',
      spotlightPadding: 10,
      content: 'This is where you can find all the information about your policy.',
      hideCloseButton: true,
      spotlightClicks: true,
      event: 'hover',
      offset: 0
    },
    {
      target: '#linkToDashboard',
      spotlightPadding: 10,
      content: 'This is where you can find your coverage and payouts.',
      hideCloseButton: true,
      spotlightClicks: true,
      event: 'hover',
      offset: 0
    },
    {
      target: '#interactiveMap',
      spotlightPadding: 10,
      content: 'On this map you can click on a province to see the rainfall since the start of your policy and the level at which you will get a payout.',
      hideCloseButton: true,
      event: 'hover',
      offset: 0
    },
    {
      target: '#closeIcon',
      spotlightPadding: 10,
      content: 'Click on the arrow to hide the menu bar.',
      hideCloseButton: true,
      event: 'hover',
      offset: 0
    },
  ]);

  const handleJoyrideCallback = useCallback((data: CallBackProps) => {
    const { action, index, status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const ongoingEvents: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];
    const beforeNextStep: string[] = [EVENTS.STEP_BEFORE];
    if (ongoingEvents.includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if (finishedStatuses.includes(status)) {
      setRun(false);
      window.localStorage.setItem('joyrideFinished', 'true');
    } else if (beforeNextStep.includes(type)) {
      if (index === 0) {
        setDrawerOpened(true);
      } else if (index === 1) {
        window.scrollTo(0, 0);
      } else if (index === 2) {
        navigate('/product');
      } else if (index === 3) {
        navigate('/dashboard');
      } else if (index === 5) {
        window.scrollTo(0, 0);
      }
    }
  }, [navigate, setDrawerOpened]);

  const handleHelp = useCallback(() => {
    setRun(true);
    if (stepIndex >= steps.length) {
      setStepIndex(0);
    }
  }, [steps, stepIndex]);

  useEffect(() => {
    if (run) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto"
    }
  }, [run]);

  useEffect(() => {
    if (user.role === Role.Insuree) {
      PolicyService.getPreviousPolicyRegistration().then((policies) => setHasPreviousPolicy(policies.length > 0))
    } else {
      setHasPreviousPolicy(true);
    }
  }, [user.role]);

  return (
    <MuiDrawer
      sx={{
        flexShrink: 0,
        position: 'relative',
        overflowX: 'hidden',
        width: theme => drawerOpened ? drawerWidth : theme.spacing(9),
      }}
      variant="permanent"
      PaperProps={{
        sx: {
          background: theme => `linear-gradient(0deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
          color: 'background.default',
          border: 0,
          pt: 1,
          overflowX: 'hidden',
          width: theme => drawerOpened ? drawerWidth : theme.spacing(9),
        }
      }}
      anchor="left"
      open={drawerOpened}
    >
      <Joyride
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        showProgress={true}
        showSkipButton={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        continuous={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: envConfig.colors.secondary.main
          }
        }}
      />
      <HelpIcon id='helpIcon' sx={{
        position: 'absolute',
        right: theme => theme.spacing(drawerOpened ? 1 : 3),
        cursor: 'pointer'
      }} onClick={handleHelp} />
      {drawerOpened && (
        <CloseIcon
          id='closeIcon'
          sx={{
            position: 'absolute',
            right: theme => theme.spacing(1),
            top: theme => theme.spacing(5),
            cursor: 'pointer'
          }}
          onClick={() => setDrawerOpened(false)}
        />
      )}
      {!drawerOpened && (
        <OpenIcon
          id='openIcon'
          sx={{
            position: 'absolute',
            right: theme => theme.spacing(3),
            top: theme => theme.spacing(5),
            cursor: 'pointer'
          }}
          onClick={() => setDrawerOpened(true)}
        />
      )}
      {drawerOpened && (
        <>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            '&>img': {
              width: '50%'
            }
          }} >
            <img src={envConfig.logo_on_dark} alt='Logo' />
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            {env !== 'prod' && (
              <Chip
                label={env}
                sx={{
                  width: 'fit-content',
                  alignSelf: 'center',
                  m: 1,
                  color: 'white',
                  backgroundColor: env === 'dev' ? '#593CB8' : env === 'staging' ? '#FF3D3D' : '#B02AB0'
                }}
              />
            )}
            <Chip
              label={user.role}
              sx={{
                width: 'fit-content',
                alignSelf: 'center',
                m: 1,
                color: 'white',
                backgroundColor: '#ffffff4a'
              }}
            />
          </Box>
        </>
      )}
      <List sx={{
        pl: 1,
        '& .MuiListItemIcon-root': {
          minWidth: theme => theme.spacing(5)
        },
        mt: theme => drawerOpened ? 0 : theme.spacing(8)
      }} >
        {filterMenuWithUserRole(user.role).filter((menuItem) => hasPreviousPolicy || menuItem.id !== 'linkToPolicyRegistration').map((menuItem, index) => (
          <DrawerListItem
            key={index}
            drawerOpened={drawerOpened}
            name={menuItem.name}
            id={menuItem.id}
            link={menuItem.link}
            icon={menuItem.icon}
            roles={menuItem.roles}
            subMenu={menuItem.subMenu}
          />
        ))}
      </List>
      {drawerOpened && (
        <>
          <Button
            sx={{
              margin: 'auto 16px 16px 16px',
              '&:hover': {
                  backgroundColor: '#0000003A',
              }
            }}
            color='inherit'
            variant='outlined'
            onClick={() => { AuthService.logout() }}
            href='\'
          >
            Logout
          </Button>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            pt: 0,
            width: '100%',
            '& a': {
              width: '50%',
              '& img': {
                width: '100%'
              }
            }
          }}>
            <Typography>Powered by</Typography>
            <a href='https://ibisa.network/'>
              <img src='/logo_ibisa_white.png' alt='IBISA' />
            </a>
          </Box>
        </>
      )}
    </MuiDrawer>
  );
};

export default Drawer;
