import { Box, Card, CardContent, CardMedia, Chip, Divider, Typography } from "@mui/material";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { PolicyStatus } from "../../../models/Policy";
import UtilsService from "../../../services/Utils";
import Cover from "../../../models/Cover";

type Props = {
  status: PolicyStatus;
  preniumToPay: number;
  covers: Cover[] | null;
};

function PolicyRegistrationStatus({ status, preniumToPay, covers }: Props) {
  return (
    <Card>
      <CardMedia
        component="div"
        sx={{
          overflow: "hidden",
          background: (theme) =>
            status === PolicyStatus.pending
              ? `linear-gradient(0deg, ${theme.palette.secondary.light} 0%, ${theme.palette.secondary.main} 100%)`
              : `linear-gradient(0deg, ${theme.palette.success.light} 0%, ${theme.palette.success.main} 100%)`,
          height: (theme) => theme.spacing(15),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {status === PolicyStatus.pending ? (
          <HourglassTopIcon
            color="secondary"
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              borderRadius: "100%",
              p: 8,
              boxSizing: "content-box",
              fontSize: "5rem",
            }}
          />
        ) : (
          <CheckCircleIcon
            color="success"
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
              borderRadius: "100%",
              p: 8,
              boxSizing: "content-box",
              fontSize: "5rem",
            }}
          />
        )}
      </CardMedia>
      <CardContent>
        {status === PolicyStatus.pending ? (
          <Typography>
            Thank you for registering for a new 2023-2024 insurance policy. You
            are seeing this screen because we are still awaiting your premium
            payment or are in the process of validating it. Please ensure that you
            have paid the premium of {UtilsService.formatCurrency(preniumToPay)}.
            Once we have validated it, the status of your policy will change to 'in force'.
          </Typography>
        ) : (
          <Typography>
            Your premium payment has been validated and your insurance policy
            for 2023-2024 is now in force. It will be activated on 1 October
            2023 when your 2022-2023 policy has expired.
          </Typography>
        )}
        <Divider sx={{ my: 2 }}><Chip label="Summary" /></Divider>
        
        {covers && (
        <>
          {covers.map((cover, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                overflow: "hidden",
                "::before": {
                  float: "left",
                  width: 0,
                  whiteSpace: "nowrap",
                  content:
                    "'...........................................................................................................................................................................................................................'",
                  color: "#ccc"
                },
              }}
            >
              <Typography display="inline" sx={{ backgroundColor: theme => theme.palette.background.paper, pr: 1 }}>{cover.province.name}</Typography>
              <Typography display="inline" sx={{ backgroundColor: theme => theme.palette.background.paper, pl: 1, ml: "auto" }}>
                {UtilsService.formatCurrency(Number(cover.amount))}
              </Typography>
            </Box>
          ))}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              overflow: "hidden",
              "::before": {
                float: "left",
                width: 0,
                whiteSpace: "nowrap",
                content:
                  "'...........................................................................................................................................................................................................................'",
                color: "#ccc"
              },
            }}
          >
            <Typography display="inline" sx={{ backgroundColor: theme => theme.palette.background.paper, pr: 1 }}>TOTAL</Typography>
            <Typography display="inline" sx={{ backgroundColor: theme => theme.palette.background.paper, pl: 1, ml: "auto" }}>
              {UtilsService.formatCurrency(covers.reduce((prev, curr) => prev + Number(curr.amount), 0))}
            </Typography>
          </Box>
        </>
        )}
      </CardContent>
    </Card>
  );
}

export default PolicyRegistrationStatus;
