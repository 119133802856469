import ApiService from './Api';
import EventsViewModel from '../models/EventsView';

class EventsView {

  getAllEvents(
    pageSize: number,
    pageNb: number,
    nameFilter: string
  ): Promise<[EventsViewModel[], number]> {
    return ApiService.get(`/eventsView?pageSize=${pageSize}&pageNb=${pageNb}&nameFilter=${nameFilter}`);
  }

}

export default new EventsView();
