import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import currency from "../../../configurations/currency.json";
import { useCallback, useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { blue } from "@mui/material/colors";
import { ProvinceModel, FormData } from "../PolicyRegistration";
import { NumericFormat } from "react-number-format";

type Props = {
  provinceAreas: ProvinceModel[];
  onSubmit: (data: FormData) => void;
};

function PolicyRegistrationForm({ provinceAreas, onSubmit }: Props) {
  const [searchedProvince, setSearchedProvince] =
    useState<ProvinceModel | null>(null);
  const [filteredProvinceAreas, setFilteredProvinceAreas] = useState<
    ProvinceModel[]
  >([]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<FormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "provinces",
    keyName: "key",
  });

  const selectSearchedProvince = useCallback(() => {
    if (searchedProvince) {
      append({ ...searchedProvince, color: blue[500], amount: 0 });
    }
    setSearchedProvince(null);
  }, [append, searchedProvince]);

  useEffect(() => {
    setFilteredProvinceAreas(
      provinceAreas.filter(
        (province) => !!!fields.find((field) => field.id === province.id)
      )
    );
  }, [provinceAreas, fields]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={{ mb: 2 }}>
        On this screen you can register for a new Weather Protect Insurance Policy for 2023-2024. Please select the province(s) below and enter the total loan amount that you want to insure in every province.
      </Typography>
      <Card sx={{ mb: 2, minHeight: 60 }}>
        <CardActions>
          <Autocomplete
            sx={{ ml: 1, flex: 1 }}
            options={filteredProvinceAreas}
            getOptionLabel={(option) => option.name}
            value={searchedProvince}
            onChange={(_, value) => value && setSearchedProvince(value)}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                {...params}
                placeholder="Search provinces"
              />
            )}
            onKeyDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              e.key === "Enter" && selectSearchedProvince();
            }}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            color="primary"
            sx={{ p: "10px" }}
            onClick={selectSearchedProvince}
          >
            <AddIcon />
          </IconButton>
        </CardActions>
      </Card>
      <Card sx={{ mb: 2, px: 2, maxHeight: "18rem", overflow: "auto" }}>
        <CardContent>
          {fields.length === 0 && (
            <Typography color="GrayText">Select a province</Typography>
          )}
          {fields.map((field, index) => (
            <Controller
              key={field.key}
              control={control}
              rules={{
                min: {
                  message: "The coverage amount must be more than 0",
                  value: 1,
                },
              }}
              name={`provinces.${index}.amount`}
              render={({ field: { onChange, name, value } }) => (
                <NumericFormat
                  name={name}
                  value={value}
                  onValueChange={(v) => onChange(v.value)}
                  thousandSeparator
                  customInput={TextField}
                  variant="standard"
                  sx={{ my: 1 }}
                  fullWidth
                  label={`${field.name}`}
                  error={!!errors.provinces?.[index]}
                  helperText={errors.provinces?.[index]?.amount?.message}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          color="error"
                          sx={{ ml: 3 }}
                          onClick={() => remove(index)}
                        >
                          <CancelIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        {currency.unit}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          ))}
        </CardContent>
      </Card>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          type="submit"
          disabled={fields.length === 0}
        >
          Next
        </Button>
      </Box>
    </form>
  );
}

export default PolicyRegistrationForm;
