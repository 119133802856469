import {
  Box
} from '@mui/material';

import WeatherConditionService from '../../services/WeatherCondition';
import WeatherConditionModel from '../../models/WeatherCondition';
import WeatherChart from '../WeatherChart';
import { useEffect, useState } from 'react';
import ProvinceModel from '../../models/Province';
import moment from 'moment';
import { ConditionType } from '../../models/WeatherCondition';

interface Props { province: ProvinceModel, type: ConditionType }

function DashboardProvincePopup({ province, type }: Props) {
  const [weatherConditions, setWeatherConditions] = useState<WeatherConditionModel[]>([]);
  const [startGraphDate, setStartGraphDate] = useState<moment.Moment>(moment());
  const [endGraphDate, setEndGraphDate] = useState<moment.Moment>(moment());

  useEffect(() => {
    WeatherConditionService.getByProvince(province.id.toString(), type).then(weatherConditions => {
      const startOfCoverage = province ? moment(province.covers.reduce((cover, cur) => moment(cover.policy.start).isSameOrBefore(cur.policy.start) ? cover : cur).policy.start) : null;
      const endOfCoverage = province ? moment(province.covers.reduce((cover, cur) => moment(cover.policy.end).isSameOrAfter(cur.policy.end) ? cover : cur).policy.end) : null;
      setWeatherConditions(
        weatherConditions
          .filter(condition => startOfCoverage ? moment(condition.date).isSameOrAfter(startOfCoverage) : true) // must be after coverage start
          .filter(condition => endOfCoverage ? moment(condition.date).isSameOrBefore(endOfCoverage) : true) // must be before coverage end
          .filter(condition => moment(condition.date).isSameOrBefore(moment())) // must be before current date
          .filter((condition, ind, arr) => moment(condition.date).isSameOrAfter(moment(arr[arr.length - 1].date).startOf('day').add(-3, 'months'))) // must be after last date - 3 months
          .sort((a, b) => moment(a.date) > moment(b.date) ? 1 : -1)
      );
      setStartGraphDate(startOfCoverage ? moment().add(-3, 'months').isSameOrAfter(startOfCoverage) ? moment().add(-3, 'months') : startOfCoverage : moment());
      setEndGraphDate(endOfCoverage ? moment().add(-3, 'months').isSameOrBefore(endOfCoverage) ? moment() : endOfCoverage : moment());
    },
      err => { console.log(err) }
    );
  }, [province, type])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '250px'
    }}>
      {province && (
        <WeatherChart
          values={weatherConditions}
          red_trigger={province.triggers.find((t) => t.index.name === type)?.red ?? 0}
          orange_trigger={province.triggers.find((t) => t.index.name === type)?.orange ?? 0}
          yellow_trigger={province.triggers.find((t) => t.index.name === type)?.yellow ?? 0}
          type={type}
          min_date={startGraphDate.startOf('day').valueOf()}
          max_date={endGraphDate.startOf('day').valueOf()}
          hide_legend
        />
      )}
    </Box>
  );
}

export default DashboardProvincePopup;