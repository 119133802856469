import { useCallback, useEffect, useState } from "react";
import Page from "../components/Page";
import WeatherChart from "../components/WeatherChart";
import Province from "../models/Province";
import ProvinceService from '../services/Province';
import UtilsService from '../services/Utils';
import WeatherConditionService from '../services/WeatherCondition';
import WeatherConditionModel, { ConditionType } from '../models/WeatherCondition';
import moment from "moment";
import {
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Box,
  Typography,
  TextField
} from "@mui/material";
import CustomTable from "../components/CustomTable";
import { DatePicker } from "@mui/x-date-pickers";

const headersTriggers = [
  { index: 'date', title: 'Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'type', title: 'Type' },
  { index: 'value', title: 'Value', transformer: (amount: number, type: ConditionType) => UtilsService.formatWeatherCondition(type, amount) },
  { index: 'trigger', title: 'Trigger' }
];

interface ContentTriggers {
  id: number,
  date: string,
  type: string,
  value: number,
  trigger: string,
};

export default function Historical() {
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<Province | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<moment.Moment | null>(moment());
  const [refresh, setRefresh] = useState<boolean>(false);

  const [chartPrecipitation, setChartPrecipitation] = useState<WeatherConditionModel[] | undefined>(undefined);
  const [chartWind, setChartWind] = useState<WeatherConditionModel[] | undefined>(undefined);
  const [chartProvinceTriggers, setChartProvinceTriggers] = useState<{
    rain: { red_trigger: number, orange_trigger: number, yellow_trigger: number },
    wind: { red_trigger: number, orange_trigger: number, yellow_trigger: number }
  } | undefined>(undefined);
  const [chartYear, setChartYear] = useState<moment.Moment | null>(moment());

  const [computedTriggers, setComputedTriggers] = useState<ContentTriggers[]>([]);

  useEffect(() => {
    ProvinceService.getAllProvinces()
      .then(provs => {
        setProvinces(provs.sort((a, b) => a.name.localeCompare(b.name)));
        setSelectedProvince(provs[0]);
        setChartProvinceTriggers({
          rain: {
            yellow_trigger: provs[0].triggers.find((t) => t.index.name === ConditionType.rain)?.yellow ?? 0,
            orange_trigger: provs[0].triggers.find((t) => t.index.name === ConditionType.rain)?.orange ?? 0,
            red_trigger: provs[0].triggers.find((t) => t.index.name === ConditionType.rain)?.red ?? 0,
          },
          wind: {
            yellow_trigger: provs[0].triggers.find((t) => t.index.name === ConditionType.wind)?.yellow ?? 0,
            orange_trigger: provs[0].triggers.find((t) => t.index.name === ConditionType.wind)?.orange ?? 0,
            red_trigger: provs[0].triggers.find((t) => t.index.name === ConditionType.wind)?.red ?? 0,
          }
        });
        WeatherConditionService.getByProvinceBetween(
          provs[0].id.toString(),
          moment().startOf('year').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ).then(weatherConditions => {
          setChartPrecipitation(weatherConditions.filter(condition => condition.index.name === ConditionType.rain));
          setChartWind(weatherConditions.filter(condition => condition.index.name === ConditionType.wind));
          if (weatherConditions !== undefined) {
            const triggers: ContentTriggers[] = weatherConditions
              .filter(condition => Number(condition.value) >= Number(provs[0].triggers.find((t) => t.index.name === ConditionType.rain)?.yellow ?? 0))
              .map(condition => ({
                id: condition.id,
                date: condition.date,
                type: condition.index.name,
                value: parseFloat(condition.value),
                trigger: Number(condition.value) >= Number(provs[0].triggers.find((t) => t.index.name === ConditionType.rain)?.red ?? 0)
                  ? 'red_trigger' : Number(condition.value) >= Number(provs[0].triggers.find((t) => t.index.name === ConditionType.rain)?.orange ?? 0)
                    ? 'orange_trigger' : 'yellow_trigger'
              }));
            setComputedTriggers(triggers);
          }
        })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedProvince && selectedYear) {
      const startOfYear = selectedYear.clone().startOf('year');
      const endOfYear = selectedYear.clone().endOf('year');
      setChartProvinceTriggers({
        rain: {
          yellow_trigger: selectedProvince.triggers.find((t) => t.index.name === ConditionType.rain)?.yellow ?? 0,
          orange_trigger: selectedProvince.triggers.find((t) => t.index.name === ConditionType.rain)?.orange ?? 0,
          red_trigger: selectedProvince.triggers.find((t) => t.index.name === ConditionType.rain)?.red ?? 0,
        },
        wind: {
          yellow_trigger: selectedProvince.triggers.find((t) => t.index.name === ConditionType.wind)?.yellow ?? 0,
          orange_trigger: selectedProvince.triggers.find((t) => t.index.name === ConditionType.wind)?.orange ?? 0,
          red_trigger: selectedProvince.triggers.find((t) => t.index.name === ConditionType.wind)?.red ?? 0,
        }
      });
      setChartYear(selectedYear);
      WeatherConditionService.getByProvinceBetween(
        selectedProvince.id.toString(),
        startOfYear.format('YYYY-MM-DD'),
        endOfYear.format('YYYY-MM-DD'),
      ).then(weatherConditions => {
        setChartPrecipitation(weatherConditions.filter(condition => condition.index.name === ConditionType.rain));
        setChartWind(weatherConditions.filter(condition => condition.index.name === ConditionType.wind));
      })
        .catch(err => {
          console.log(err);
        });
    }
  }, [refresh]);

  useEffect(() => {
    const triggers: ContentTriggers[] = [];
    if (selectedProvince) {
      if (chartPrecipitation !== undefined) {
        triggers.push(
          ...chartPrecipitation
            .filter(condition => Number(condition.value) >= Number(selectedProvince.triggers.find((t) => t.index.name === ConditionType.rain)?.yellow ?? 0))
            .map(condition => ({
              id: condition.id,
              date: condition.date,
              type: condition.index.name,
              value: parseFloat(condition.value),
              trigger: Number(condition.value) >= Number(selectedProvince.triggers.find((t) => t.index.name === ConditionType.rain)?.red ?? 0)
                ? 'red_trigger' : Number(condition.value) >= Number(selectedProvince.triggers.find((t) => t.index.name === ConditionType.wind)?.orange ?? 0)
                  ? 'orange_trigger' : 'yellow_trigger'
            }))
        );
      }
      if (chartWind !== undefined) {
        triggers.push(
          ...chartWind
            .filter(condition => Number(condition.value) >= Number(selectedProvince.triggers.find((t) => t.index.name === ConditionType.wind)?.yellow ?? 0))
            .map(condition => ({
              id: condition.id,
              date: condition.date,
              type: condition.index.name,
              value: parseFloat(condition.value),
              trigger: Number(condition.value) >= Number(selectedProvince.triggers.find((t) => t.index.name === ConditionType.wind)?.red ?? 0)
                ? 'red_trigger' : Number(condition.value) >= Number(selectedProvince.triggers.find((t) => t.index.name === ConditionType.wind)?.orange ?? 0)
                  ? 'orange_trigger' : 'yellow_trigger'
            }))
        );
      }
    }
    setComputedTriggers(triggers.sort((a, b) => moment(a.date).diff(moment(b.date))));
  }, [chartPrecipitation, chartWind, selectedProvince]);

  const updateTriggers = useCallback((pageNb: number, pageSize: number, filter: string) => {
    return Promise.resolve({ total: computedTriggers.length, rows: computedTriggers.filter((t, index) => index >= pageNb * pageSize && index < (pageNb + 1) * pageSize) });
  }, [computedTriggers]);

  return (
    <Page title="Historical Weather">
      <Paper sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '600px',
        p: 2,
        m: 2
      }}>
        <Box>
          <FormControl variant="standard">
            <InputLabel>Province</InputLabel>
            <Select
              value={selectedProvince ? selectedProvince.id : ''}
              autoWidth
              onChange={(evt: any) => setSelectedProvince(provinces.filter(prov => prov.id === evt.target.value)[0])}
            >
              {provinces.map(prov => (
                <MenuItem value={prov.id} key={prov.id}>{prov.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <DatePicker
            label='Year'
            views={["year"]}
            value={selectedYear}
            onChange={(v) => setSelectedYear(v)}
            renderInput={(params) => (
              <TextField {...params} variant='standard' />
            )}
          />
        </Box>
        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setRefresh(ref => !ref)}
          >Apply filter</Button>
        </Box>
      </Paper>
      <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
        <Box style={{ display: 'flex', flexDirection: 'column', width: '50%', minWidth: '632px' }}>
          <Paper sx={{ m: 2, p: 2 }}>
            <Typography variant='h6' style={{ textAlign: 'center' }}>Rainfall</Typography>
            {chartPrecipitation && (
              <Box sx={{ height: '40vh' }}>
                <WeatherChart
                  values={chartPrecipitation}
                  red_trigger={chartProvinceTriggers?.rain.red_trigger ?? 0}
                  orange_trigger={chartProvinceTriggers?.rain.orange_trigger ?? 0}
                  yellow_trigger={chartProvinceTriggers?.rain.yellow_trigger ?? 0}
                  type={ConditionType.rain}
                  min_date={chartYear?.clone().startOf('year').valueOf()}
                  max_date={chartYear?.clone().endOf('year').valueOf()}
                />
              </Box>
            )}
          </Paper>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', width: '50%', minWidth: '632px' }}>
          <Paper sx={{ m: 2, p: 2 }}>
            <Typography variant='h6' style={{ textAlign: 'center' }}>Wind Speed</Typography>
            {chartWind && (
              <Box sx={{ height: '40vh' }}>
                <WeatherChart
                  values={chartWind}
                  red_trigger={chartProvinceTriggers?.wind.red_trigger ?? 0}
                  orange_trigger={chartProvinceTriggers?.wind.orange_trigger ?? 0}
                  yellow_trigger={chartProvinceTriggers?.wind.yellow_trigger ?? 0}
                  type={ConditionType.wind}
                  min_date={chartYear?.clone().startOf('year').valueOf()}
                  max_date={chartYear?.clone().endOf('year').valueOf()}
                />
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
      <CustomTable
        headers={headersTriggers}
        updateFunction={updateTriggers}
        pageSizeOptions={[5, 10, 15]}
        disableSearch
      />
    </Page>
  );
}