import { useCallback, useEffect, useState } from "react";
import CustomTable, { RowActions, detailAction, editAction } from "../../../components/CustomTable";
import UtilsService from "../../../services/Utils";
import PoliciesService from "../../../services/Policy";
import { useNavigate } from "react-router-dom";
import PolicyDialog from "../dialogs/CoopManager.policy.dialog";
import Policy, { PolicyStatus } from "../../../models/Policy";
import { Role } from "../../../models/User";
import useUser from "../../../hooks/user";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { confirmAlert } from "react-confirm-alert";

const headers = [
  { index: 'start', title: 'Start', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'end', title: 'End', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'status', title: 'Status' },
  { index: 'totalCoverage', title: 'Total Coverage', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'lastTrigger', title: 'Last Trigger Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'totalBenefits', title: 'Total Payout', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'actions', title: 'Actions' },
];

interface Content {
  id: number,
  start: Date,
  end: Date,
  status: string,
  totalCoverage: number,
  lastTrigger: string,
  totalBenefits: number,
  actions: JSX.Element
};

interface Props {
  coopId: number,
}
function CoopDetailsPoliciesPanel({ coopId }: Props) {
  const { user } = useUser();
  const navigate = useNavigate();
  const [selectedPolicy, setSelectedPolicy] = useState<Policy>();
  const [isPolicyDialogOpened, tooglePolicyDialog] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  const detailActionClick = useCallback((policyId: number) => () => {
    navigate(`/coopManager/${coopId}/policy/${policyId}`);
  }, [coopId, navigate]);

  const inForceActionClick = useCallback((policyId: number) => () => {
    confirmAlert({
      title: "Confirm policy enforcement",
      message: "Have you check everything?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            PoliciesService.updatePolicy(policyId, { status: PolicyStatus.inForce }).then(() => {
              setReloadData(reload => !reload);
              console.log("coucou")
            })
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }, []);

  const editPolicyAction = useCallback((policy: Policy) => {
    setSelectedPolicy(policy);
  }, []);

  const updatePolicies = useCallback((pageNb: number, pageSize: number, filter: string) => {
    return PoliciesService.getPaginatedPoliciesByInsuree(coopId, pageSize, pageNb).then(res => {
      const contents = res[0].map(policy => {
        const benefits = policy.covers.map(c => c.benefits).flat();
        const content: Content = {
          id: policy.id,
          start: policy.start,
          end: policy.end,
          status: policy.status,
          totalCoverage: policy.covers.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0),
          lastTrigger: benefits.length > 0 ? benefits.reduce((acc, curr) => acc.date > curr.date ? acc : curr).date : '-',
          totalBenefits: benefits.length > 0 ? benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0) : 0,
          actions: <RowActions elementId={policy.id} actions={[
            detailAction(detailActionClick(policy.id), false),
            ...(policy.status === PolicyStatus.pending ? [
              { // set status to 'in force'
                click: inForceActionClick(policy.id),
                disabled: policy.covers.length === 0,
                icon: <CheckCircleIcon />,
                color: "secondary" as const,
                tooltip: "Validate policy",
              },
            ] : []),
            editAction(() => editPolicyAction(policy), user.role === Role.Insuree),
          ]} />
        };
        return content;
      });
      return { total: res[1], rows: contents };
    });
  }, [coopId, detailActionClick, editPolicyAction, inForceActionClick, user.role]);

  useEffect(() => {
    if (selectedPolicy) {
      tooglePolicyDialog(true);
    }
  }, [selectedPolicy]);

  return (
    <>
      <CustomTable
        headers={headers}
        updateFunction={updatePolicies}
        reloadData={reloadData}
        pageSizeOptions={[5, 10, 15]}
        disableSearch
        addButtonAction={user.role === Role.Insuree ? undefined : () => tooglePolicyDialog(true)}
        addButtonLabel="Add Policy"
        addButtonDisabled={user.role === Role.Insuree}
        noDataText="No policies."
      />
      <PolicyDialog
        policy={selectedPolicy}
        coopId={coopId}
        opened={isPolicyDialogOpened}
        onConfirm={(id) => {
          tooglePolicyDialog(false);
          setSelectedPolicy(undefined);
          setReloadData(reload => !reload);
          navigate(`/coopManager/${coopId}/policy/${id}`);
        }}
        onClose={() => {
          tooglePolicyDialog(false);
          setSelectedPolicy(undefined);
        }}
      />
    </>
  )
}

export default CoopDetailsPoliciesPanel;