import { useMemo } from 'react';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from '@mui/material';

import InsureeSelector from '../components/InsureeSelector';
import Page from '../components/Page';
import TriggerDot from '../components/TriggerDot';
import useUser from '../hooks/user';
import { Role } from '../models/User';
//import InsureeService from '../services/Insuree';
import payoutConfig from '../configurations/payouts.json';
import { withStyles } from '@mui/styles';
import wcConfig from '../configurations/weatherConditions.json';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
    fontWeight: 'bold'
  },
}))(TableCell);

function Product() {
  /* const allFileTypes = InsureeService.getFileTypes();
  const [files, setFiles] = useState<InsureeFile[]>([]); */
  const { user, selectedInsureeId } = useUser();

  const yellowTriggerColor = useMemo(() => wcConfig.triggers.yellowTrigger.name.split(' ')[0], []);
  const orangeTriggerColor = useMemo(() => wcConfig.triggers.orangeTrigger.name.split(' ')[0], []);
  const redTriggerColor = useMemo(() => wcConfig.triggers.redTrigger.name.split(' ')[0], []);

  /* useEffect(() => {
    if (selectedInsureeId !== null) {
      InsureeService.getFiles(selectedInsureeId)
      .then((files) => {
        setFiles(files.filter((file) => !file.hidden));
      });
    }
  }, [selectedInsureeId]); */

  /* const getFileTypeLabel = (id: string) => allFileTypes.find(({ id: tId }) => tId === id)?.label || id;

  const openFile = async (file: InsureeFile) => {
    const fileData = await InsureeService.getFile(selectedInsureeId!, file.id);
    const blob = new Blob([fileData], {
      type: "application/pdf"
    });
    const objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl);
  }; */

  return (
    <Page title="Product">
      <>
        {user.role !== Role.Insuree && (
          <InsureeSelector />
        )}
        <Paper sx={{ m: 2, p: 2 }}>
          <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
            Product Specifications
          </Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <Typography sx={{ m: 2 }}>
              This protection is designed to protect the loan portfolio of the farmers in your cooperative.
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            '&>img': {
              width: '2.4rem'
            }
          }}>
            <img src='/icons/umbrella.png' alt='umbrella' />
            <Typography sx={{ m: 2 }}>
              Your protection is valid until 30 September 2024 and it protects the loan portfolio against excessive rain and wind.
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            '&>img': {
              width: '2.4rem'
            }
          }}>
            <img src='/icons/philippines.png' alt='philippines' />
            <Typography sx={{ m: 2 }}>
              Protection is subBoxided by province. In every province precipitation and windspeed are measured and summarized per one-day period.
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            '&>img': {
              width: '2.4rem'
            }
          }}>
            <img src='/icons/time.png' alt='time' />
            <Typography sx={{ m: 2 }}>
              Precipitation and wind speed data will be updated with a 6 day delay.
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            '&>img': {
              width: '2.4rem'
            }
          }}>
            <img src='/icons/rain.png' alt='rain' />
            <Typography sx={{ m: 2 }}>
              Every province has three triggers ({yellowTriggerColor}, {orangeTriggerColor} and {redTriggerColor}) for wind and rain. If the recorded rainfall or windspeed exceeds the trigger level, you will receive a payout (5%, 10% or 20% of the coverage for this province). To see specific trigger values for a province, go to the Dashboard page and click on the province name.
            </Typography>
          </Box>

          <TableContainer sx={{
            border: theme => `solid 1px ${theme.palette.grey[300]}`,
            borderRadius: theme => theme.spacing(0.5),
          }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Trigger</StyledTableCell>
                  <StyledTableCell align="center">Payout</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <TriggerDot level={0} />
                    {yellowTriggerColor}
                  </TableCell>
                  <TableCell align="center">{`${payoutConfig.yellow_payout}%`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <TriggerDot level={1} />
                    {orangeTriggerColor}
                  </TableCell>
                  <TableCell align="center">{`${payoutConfig.orange_payout}%`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <TriggerDot level={2} />
                    {redTriggerColor}
                  </TableCell>
                  <TableCell align="center">{`${payoutConfig.red_payout}%`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            '&>img': {
              width: '2.4rem'
            }
          }}>
            <img src='/icons/research.png' alt='research' />
            <Typography sx={{ m: 2 }}>
              For example, a cooperative has 10 000 000 ₱ of coverage in Leyte and one day it was raining excessively – the precipitation value reached the {orangeTriggerColor} trigger (10%). Then, the cooperative is entitled 10 000 000 x 10 % = 1 000 000 ₱
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <Typography sx={{ m: 2 }}>
              Multiple triggers can be activated per year, with a maximum payout per province of 50% per year, and a maximum of 20% for wind speed per province per typhoon.
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <Typography sx={{ m: 2 }} style={{ marginBottom: 0 }}>
              Examples:
            </Typography>
          </Box>
          <Box sx={{
            ml: 2,
            display: 'flex',
            alignItems: 'center',
          }}>
            <Typography sx={{ m: 2 }}>
              1.	One cooperatie gets two {redTriggerColor} (20%) trigger and one {orangeTriggerColor} wind trigger (10%) in one year. Their total payout that year will be 20% + 20% + 10% = 50% of coverage
            </Typography>
          </Box>
          <Box sx={{
            ml: 2,
            display: 'flex',
            alignItems: 'center',
          }}>
            <Typography sx={{ m: 2 }}>
              2.	One cooperative gets three {redTriggerColor} (20%) triggers in one year. Their total payout that year will be 50% of coverage because of the yearly maximum of 50%.
            </Typography>
          </Box>
          <Box sx={{
            ml: 2,
            display: 'flex',
            alignItems: 'center',
          }}>
            <Typography sx={{ m: 2 }}>
              3.	For one typhoon, one cooperative has two {redTriggerColor} wind triggers on two subsequent days. Their payout will be 20% because of the maximum wind speed trigger per typhoon, per province.
            </Typography>
          </Box>

          <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
            Claim Process
          </Typography>
          <Typography sx={{ m: 2 }}>
            • Within 6 working days after the event, the coop will receive an email notification on their coop email and in the coop’s dashboard under “Triggered Events”. CLIMBS will also call a cooperative representative to notify them of the payout.
          </Typography>
          <Typography sx={{ m: 2 }}>
            • CLIMBS will release the payout within 10 working days after the event.
          </Typography>

          {/* <Box mt={3}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              Downloadable documents
            </Typography>
            <List>
              {files.map((file) => (
                <ListItemButton key={file.id} onClick={() => openFile(file)}>
                  <ListItemIcon><PdfIcon style={{ color: 'rgb(201, 37, 25)' }} /></ListItemIcon>
                  <ListItemText primary={getFileTypeLabel(file.type)} />
                </ListItemButton>
              ))}
            </List>
          </Box> */}
        </Paper>
      </>
    </Page>
  );
}

export default Product;