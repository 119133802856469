import {
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { useEffect, useState } from 'react';

import TriggerDot from '../../components/TriggerDot';
import UtilsService from '../../services/Utils';
import EventModel from '../../models/EventsView';
import BenefitService from '../../services/Benefit';
import { confirmAlert } from 'react-confirm-alert';
import '../modals/css/react-confirm-alert.css';
import { PolicyStatus } from '../../models/Policy';

interface Header {
  index: keyof EventModel,
  title: string,
  special: string,
  transformer: Function
}

interface CustomTableProps {
  headers: Header[];
  index: number;
  content: EventModel;
  onChangeSelected: Function;
  onSave: Function;
  onDelete: Function;
  selected: boolean;
}

enum Trigger {
  yellow = 'yellow_trigger',
  orange = 'orange_trigger',
  red = 'red_trigger'
}

enum Status {
  pending = 'pending',
  accepted = 'accepted'
}

function PendingEventsRow({
  headers,
  index,
  content,
  onChangeSelected,
  onSave,
  onDelete,
  selected
}: CustomTableProps) {
  const [selectedTriggerLevel, setSelectedTriggerLevel] = useState<Trigger>(Trigger.yellow);
  const [status, setStatus] = useState<Status>(Status.pending);
  const [enabled, setEnabled] = useState<boolean>(true);

  useEffect(() => {
    switch (content.trigger) {
      case 'orange_trigger':
        setSelectedTriggerLevel(Trigger.orange);
        break;
      case 'red_trigger':
        setSelectedTriggerLevel(Trigger.red);
        break;
      default:
        setSelectedTriggerLevel(Trigger.yellow);
        break;
    }
    switch (content.status) {
      case 'accepted':
        setStatus(Status.accepted);
        setEnabled(false);
        break;
      default:
        setStatus(Status.pending);
        setEnabled(true);
        break;
    }
  }, [content]);

  const handleChangeSelected = (rowNb: number) => {
    onChangeSelected(rowNb);
  }

  const handleChangeSelectedTriggerLevel = (triggerLevel: Trigger) => {
    switch (triggerLevel) {
      case Trigger.yellow:
        onChangeSelected(0);
        break;
      case Trigger.orange:
        onChangeSelected(1);
        break;
      case Trigger.red:
        onChangeSelected(2);
        break;
      default:
        onChangeSelected(0);
        break;
    }
    setSelectedTriggerLevel(triggerLevel);
  }

  const handleChangeStatus = (status: Status) => {
    setStatus(status);
  }

  const confirmSave = () => {
    confirmAlert({
      title: 'Confirm to save',
      message: content.policyStatus === PolicyStatus.pending ? 'There are PENDING policies for this event, if you confirm no benefits will be generated for them ! Are you sure you want to do this.' : 'Are you sure you want to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: handleSave
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }

  const handleSave = () => {
    BenefitService.createBenefitsWithEvent({
      date: content.date,
      weatherConditionId: content.weatherConditionId,
      provinceId: content.provinceId,
      trigger: selectedTriggerLevel
    }).then(() => {
      onSave();
    });
  }

  const confirmDelete = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: handleDelete
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }

  const handleDelete = () => {
    BenefitService.deleteBenefitsFromEvent(
      content.weatherConditionId
    ).then(() => {
      onDelete();
    });
  }

  return (
    <TableRow
      onClick={() => { handleChangeSelected(index) }}
      hover
      selected={selected}
    >
      {headers.map((header, index) => {
        if (header.special) {
          switch (header.special) {
            case 'triggerSelection':
              return (
                <TableCell key={index} align="center">
                  <FormControl variant="standard">
                    <Select
                      value={selectedTriggerLevel}
                      autoWidth
                      onChange={(evt: any) => handleChangeSelectedTriggerLevel(evt.target.value)}
                      disabled={!enabled}
                    >
                      <MenuItem value={Trigger.yellow}><TriggerDot level={0} /></MenuItem>
                      <MenuItem value={Trigger.orange}><TriggerDot level={1} /></MenuItem>
                      <MenuItem value={Trigger.red}><TriggerDot level={2} /></MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              );
            case 'accepted':
              return (
                <TableCell key={index} align="center">
                  <FormControl variant="standard">
                    <Select
                      value={status}
                      autoWidth
                      onChange={(evt: any) => handleChangeStatus(evt.target.value)}
                      disabled={!enabled}
                    >
                      <MenuItem value={Status.pending}>{Status.pending}</MenuItem>
                      <MenuItem value={Status.accepted}>{Status.accepted}</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              );
            case 'diff':
              return (
                <TableCell key={index} align="center">
                  {UtilsService.computePrecipitationDiff(content.value, content[selectedTriggerLevel])}
                </TableCell>
              );
            case 'save':
              return (
                <TableCell key={index} align="center">
                  <Button
                    variant='contained'
                    color='secondary'
                    disabled={!enabled || status !== Status.accepted}
                    onClick={() => { confirmSave() }}
                  >
                    Save
                  </Button>
                  <Button
                    variant='contained'
                    sx={{
                      ml: 0.5,
                      backgroundColor: '#CD3D3D',
                      color: '#FFF',
                      '&:hover': {
                        backgroundColor: '#AD1F1F',
                      }
                    }}
                    disabled={enabled || status !== Status.accepted}
                    onClick={() => { confirmDelete() }}
                  >
                    delete
                  </Button>
                </TableCell>
              );
            default:
              return (<></>);
          }
        } else {
          if (header.transformer) {
            return (
              <TableCell key={index} align="center">{header.transformer(content[header.index], content.type)}</TableCell>
            )
          } else {
            return (
              <TableCell key={index} align="center">{content[header.index]}</TableCell>
            )
          }
        }
      })}
    </TableRow>
  );
}

export default PendingEventsRow;