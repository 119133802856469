import { useMap } from "react-leaflet";
import L, { Control } from "leaflet";
import { useEffect, useState } from "react";
import "./css/toggle.css";

interface Props {
  defaultValue: boolean;
  onToggle: (value: boolean) => void;
}

const ToggleControl = ({ defaultValue, onToggle }: Props) => {
  const map = useMap();
  const [_defaultValue] = useState(defaultValue);

  useEffect(() => {
    const ToggleControl: Control = L.control.attribution({
      position: "topright",
    });

    ToggleControl.onAdd = () => {
      const label = L.DomUtil.create("label", "leaflet-bar leaflet-control");
      label.className = "switch tooltip";

      const input = document.createElement("input");
      input.type = "checkbox";
      input.checked = _defaultValue;
      input.onclick = (ev: any) => onToggle(ev.target.checked);

      const span = document.createElement("span");
      span.className = "slider round";

      const tooltip = document.createElement("span");
      tooltip.className = "tooltiptext";
      tooltip.textContent = "Only show my coverages";

      label.appendChild(input);
      label.appendChild(span);
      label.appendChild(tooltip);
      return label;
    };

    ToggleControl.addTo(map);
    return () => {
      ToggleControl.remove();
    };
  }, [map, onToggle, _defaultValue]);

  return null;
};

export default ToggleControl;
