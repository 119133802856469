import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  debounce,
  CircularProgress,
  Box
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import PendingEventsRow from '../admin/PendingEventsTable.row';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    fontWeight: 'bold'
  },
}))(TableCell);

interface CustomTableProps {
  headers: any[];
  content: any[];
  total: number;
  pageSize: number;
  pageNb: number;
  onChangePage: Function;
  onChangeRowsPerPage: Function;
  onChangeSearch: Function;
  disableSearch?: boolean;
  pageSizeOptions?: number[];
  onChangeSelected: Function;
  onSave: Function;
  onDelete: Function;
  isLoading: boolean
}

function PendingEventsTable({
  headers,
  content,
  total,
  pageSize,
  pageNb,
  onChangePage,
  onChangeRowsPerPage,
  onChangeSearch,
  disableSearch = false,
  pageSizeOptions = [5, 10, 20],
  onChangeSelected,
  onSave,
  onDelete,
  isLoading,
}: CustomTableProps) {
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState(-1);

  const chageSearch = useCallback(
    debounce((name: string) => {
      onChangeSearch(name);
    }, 200),
    []
  );

  useEffect(() => {
    chageSearch(search);
  }, [search])

  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage(pageSize, newPage);
    setSelected(-1);
    onChangeSelected(-1);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRowsPerPage(+event.target.value);
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setSelected(-1);
    onChangeSelected(-1);
  }

  const handleChangeSelected = (rowNb: number) => {
    setSelected(rowNb);
    onChangeSelected(rowNb);
  }

  return (
    <Paper sx={{ m: 2, p: 2 }}>
      <TableContainer sx={{
        border: theme => `solid 1px ${theme.palette.grey[300]}`,
        borderRadius: theme => theme.spacing(0.5),
      }}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StyledTableCell key={index} align="center">{header.title}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={11}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={80} />
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {!isLoading && content.map((row, index) => (
              <PendingEventsRow
                key={index}
                headers={headers}
                index={index}
                content={row}
                onChangeSelected={handleChangeSelected}
                onSave={onSave}
                onDelete={onDelete}
                selected={selected === index}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {!disableSearch && (
                <TableCell>
                  <TextField
                    size="small"
                    label="Search"
                    variant="outlined"
                    color="secondary"
                    onChange={handleChangeSearch}
                  />
                </TableCell>
              )}
              <TablePagination
                count={total}
                page={pageNb}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={pageSizeOptions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default PendingEventsTable;