import {
  Backdrop,
  Box,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Page from "../../components/Page";
import InteractivePhilippinesMap from "../../components/InteractiveMap/InteractivePhilippinesMap";
import useDrawer from "../../hooks/drawer";
import { useCallback, useEffect, useState } from "react";
import ProvinceService from "../../services/Province";
import PolicyService from "../../services/Policy";
import { MultiPolygon } from "@turf/turf";
import { green, grey, yellow } from "@mui/material/colors";
import { FormProvider, useForm } from "react-hook-form";
import PolicyRegistrationForm from "./steps/PolicyRegistrationForm";
import PolicyRegistrationSummary from "./steps/PolicyRegistrationSummary";
import PolicyRegistrationStatus from "./steps/PolicyRegistrationStatus";
import { confirmAlert } from "react-confirm-alert";
import payoutConfig from "../../configurations/payouts.json";
import defaultPolicyDatesConfig from "../../configurations/defaultPolicyDates.json";
import Policy, { PolicyStatus } from "../../models/Policy";
import InsureeSelector from "../../components/InsureeSelector";
import { Role } from "../../models/User";
import useUser from "../../hooks/user";

export type ProvinceModel = {
  id: number;
  name: string;
  path: MultiPolygon;
  color: string;
};

export type FormData = {
  provinces: (ProvinceModel & { amount: number })[];
};

const steps = [
  "Select provinces and coverage",
  "Registration summary",
  "Registration status",
];

function PolicyRegistration() {
  const { drawerOpened } = useDrawer();
  const { user, selectedInsureeId } = useUser();
  const [activeStep, setActiveStep] = useState(0);
  const [provinceAreas, setProvinceAreas] = useState<ProvinceModel[]>([]);
  const [selectedProvinces, setSelectedProvinces] = useState<FormData | null>(
    null
  );
  const [policy, setPolicy] = useState<Policy>();
  const [loading, setLoading] = useState(false);

  const methods = useForm<FormData>({
    defaultValues: { provinces: [] },
  });
  const provincesWatcher = methods.watch("provinces");

  const onSubmit = useCallback((data: FormData) => {
    setSelectedProvinces(data);
    setActiveStep(1);
    methods.reset();
  }, [methods]);

  const onCancel = useCallback(
    (index: number) => () => {
      setActiveStep(index - 1);
    },
    []
  );

  const sendRegistration = useCallback((): Promise<Policy> => {
    if (selectedProvinces) {
      return PolicyService.registerForPolicy(
        selectedProvinces.provinces.map((province) => ({
          amount: province.amount,
          province: province.id,
          red_payout: payoutConfig.red_payout,
          orange_payout: payoutConfig.orange_payout,
          yellow_payout: payoutConfig.yellow_payout,
        })),
        (user.role !== Role.Insuree ? selectedInsureeId : undefined) ?? undefined,
      );
    } else {
      // should not be able to end up here
      return new Promise(() => {});
    }
  }, [selectedInsureeId, selectedProvinces, user.role]);

  const onConfirm = useCallback(() => {
    confirmAlert({
      title: "Confirm registration",
      message: "Have you checked everything?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoading(true);
            sendRegistration()
              .then((policy) => {
                setPolicy(policy);
                setActiveStep(2);
                setSelectedProvinces((form) =>
                  form
                    ? {
                        provinces: [
                          ...form.provinces.map((province) => ({
                            ...province,
                            color: yellow[500],
                          })),
                        ],
                      }
                    : null
                );
              })
              .finally(() => setLoading(false));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }, [sendRegistration]);

  useEffect(() => {
    ProvinceService.getAllProvinceAreas().then((res) =>
      setProvinceAreas(
        res.map((r) => ({ id: r.id, name: r.name, path: r.path, color: grey[500] }))
      )
    );
  }, []);

  useEffect(() => {
    setSelectedProvinces(null);
    PolicyService.getPolicyRegistration(
      user.role !== Role.Insuree ? selectedInsureeId ?? undefined : undefined
    ).then((res) => {
      if (res.length > 0) {
        setPolicy(res[0]);
        setSelectedProvinces({
          provinces: res[0].covers.map((cover) => ({
            id: cover.province.id,
            name: cover.province.name,
            color: res[0].status === PolicyStatus.pending ? yellow[500] : green[500],
            path: cover.province.path as MultiPolygon,
            amount: parseFloat(cover.amount)
          }))
        })
      } else {
        setPolicy(undefined);
      }
    });
  }, [user, selectedInsureeId]);

  useEffect(() => {
    if (policy) {
      setActiveStep(2);
    } else {
      setActiveStep(0);
    }
  }, [policy]);

  return (
    <Page title="">
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 2,
            pr: 10,
            pl: 7,
            width: "100%",
            maxWidth: "900px",
            minWidth: "600px",
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          <Typography variant="h4">Register for</Typography>
          <Typography variant="h3" textAlign="center">
            {defaultPolicyDatesConfig.product}
          </Typography>
          {user.role !== Role.Insuree && <InsureeSelector />}
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{ mt: 4, mb: 6 }}
          >
            {steps.map((label) => (
              <Step color="success" key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box hidden={activeStep !== 0} width="100%">
            <FormProvider {...methods}>
              <PolicyRegistrationForm
                provinceAreas={provinceAreas}
                onSubmit={onSubmit}
              />
            </FormProvider>
          </Box>
          <Box hidden={activeStep !== 1} width="100%">
            <PolicyRegistrationSummary
              onCancel={onCancel(1)}
              onConfirm={onConfirm}
              selectedProvinces={selectedProvinces}
            />
          </Box>
          <Box hidden={activeStep !== 2} width="100%">
            <PolicyRegistrationStatus
              status={policy?.status ?? PolicyStatus.pending}
              covers={policy?.covers ?? []}
              preniumToPay={policy ? (parseFloat(policy.product.prenium) * policy.covers.reduce((prev, curr) => prev + Number(curr.amount), 0)) : 0}
            />
          </Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress size="8rem" color="inherit" />
          </Backdrop>
        </Box>
        <Box
          display={{ md: "none", lg: "block" }}
          sx={{
            position: "relative",
            zIndex: 0,
            top: (theme) => theme.spacing(-5),
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            p={3}
            id="interactiveMap"
            sx={{
              width: (theme) => `calc(100% + ${theme.spacing(3)})`,
              height: (theme) => `calc(100% + ${theme.spacing(8)})`,
              m: 0,
              p: 0,
              "& > *": {
                width: "100%",
                height: "100%",
                m: 0,
                p: 0,
              },
              "& > div": {
                m: 0,
                p: 0,
                boxShadow: "none",
                height: "100%",
              },
              boxShadow: "-3px 0 20px 0px #9f9f9f",
            }}
          >
            <InteractivePhilippinesMap
              provinces={
                activeStep === 0
                  ? provincesWatcher
                  : selectedProvinces
                  ? selectedProvinces.provinces
                  : []
              }
              showOnlyCoveredProvinces
              onChangeshowOnlyCoveredProvinces={() => {}}
              onSelectProvice={() => {}}
              selectedProvince={
                activeStep === 0
                  ? provincesWatcher.length > 0
                    ? provincesWatcher[provincesWatcher.length - 1].id
                    : undefined
                  : undefined
              }
              resize={drawerOpened}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

export default PolicyRegistration;
